<template>
  <div>
    <v-col v-if="cartItems && cartItems.length">
      <v-card
        elevation="0"
        class="mb-4"
        color="white"
        tile
        v-for="(product, index) in cartItems.slice().reverse()"
        v-bind:key="index"
      >
        <v-row class="mx-1">
          <v-col cols="4" class="pt-6">
            <div v-if="product.customAttributes.isPersonalize == 1">
              <div v-if="builderSession(product)">
                <div
                  v-for="(item, itemIndex) in product.thumbnailUrl"
                  :key="itemIndex"
                  class="mb-2"
                >
                  <v-img
                    v-bind:src="
                      item
                        ? item
                        : imageBasePathUrl +
                          product.customAttributes.thumbnailUrl
                    "
                  ></v-img>
                </div>
              </div>
              <div v-else>
                <v-img
                  v-bind:src="imageBasePathUrl + product.customAttributes.image"
                ></v-img>
              </div>
            </div>
            <div v-else>
              <v-img
                v-bind:src="imageBasePathUrl + product.customAttributes.image"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="8" v-show="!cartProductPage">
            <v-row class="ma-0">
              <v-col class="px-0 pt-2 pb-3 ma-0">
                <p
                  class="fontsize-14 text-black text-left cursor-pointer"
                  @click="
                    goToProductDetails(
                      product.customAttributes,
                      product.sku,
                      product.productType,
                      product.parentSku
                    )
                  "
                >
                  {{ product.name }}
                </p>
              </v-col>
            </v-row>
            <v-expansion-panels
              class="pa-0 mt-n3 active-panel-edited"
              flat
              v-show="
                product.productType == 'configurable' ||
                product.productType == 'giftcard'
              "
            >
              <v-expansion-panel
                elevation="0"
                class="pa-0 ma-0"
                v-if="product.productOption"
              >
                <v-expansion-panel-title class="pa-0 ma-0 custom-panel-title"
                  >See Details</v-expansion-panel-title
                >
                <v-expansion-panel-text
                  v-if="product.productType == 'giftcard'"
                  class="cartproductlist-expansion custom-panel-text"
                >
                  <p
                    class="font-weight-bold fontsize-14 text-black text-left cursor-pointer mb-1"
                  >
                    Gift Card Sender
                  </p>
                  <p
                    v-if="product.productOption.infoBuyRequest"
                    class="fontsize-14 text-black text-left cursor-pointer"
                  >
                    {{
                      product.productOption.infoBuyRequest.giftcardSenderName
                    }}
                    <br />
                    {{
                      product.productOption.infoBuyRequest.giftcardSenderEmail
                    }}
                  </p>
                  <p
                    class="font-weight-bold fontsize-14 text-black text-left cursor-pointer mt-3 mb-1"
                  >
                    Gift Card Recipient
                  </p>
                  <p
                    v-if="product.productOption.infoBuyRequest"
                    class="fontsize-14 text-black text-left cursor-pointer"
                  >
                    {{
                      product.productOption.infoBuyRequest.giftcardRecipientName
                    }}
                    <br />
                    {{
                      product.productOption.infoBuyRequest
                        .giftcardRecipientEmail
                    }}
                  </p>
                </v-expansion-panel-text>
                <v-expansion-panel-text
                  v-else
                  class="cartproductlist-expansion custom-panel-text"
                >
                  <p
                    class="font-weight-bold fontsize-14 text-black text-left cursor-pointer mb-1"
                  >
                    Letter
                  </p>
                  <p
                    v-if="product.productOption.attributesInfo.length > 0"
                    class="fontsize-14 text-black text-left cursor-pointer"
                  >
                    {{ product.productOption.attributesInfo[0].value }}
                  </p>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <p class="fontsize-14 text-left">
              ${{ priceFormatter(product.price) }}
            </p>

            <div v-if="pageName() != 'CheckoutPage'">
              <v-row
                v-if="product.customAttributes.isPersonalize == 1"
                align="center"
              >
                <v-col cols="auto" class="pr-1 pt-4">
                  <p class="fontsize-14 mb-0 pb-7">Qty.</p>
                </v-col>
                <v-col class="pl-0 pt-4">
                  <v-hover v-slot="{ isHovering, props }">
                    <v-select
                      :items="
                        qtyPriceOptions(
                          product.customAttributes.qtyPrice,
                          product.qty,
                          index
                        )
                      "
                      class="fontsize-16 line-height-21 ls-n009 font-weight-400 changeOnHover cartproductlist-select-button seTextBoxWidth"
                      variant="outlined"
                      density="compact"
                      v-bind="props"
                      hide-details
                      attach
                      :class="isHovering ? 'text-primary' : 'text-black'"
                      v-model="selectedQty[index]"
                      @update:modelValue="
                        updateToCart(
                          product.sku,
                          product.itemId,
                          index,
                          product.price,
                          product.personalizeDetails,
                          product.productOption,
                          product.customAttributes.letter,
                          product.productType,
                          product.customAttributes.isPersonalize,
                          product.customAttributes.builderVersion,
                          product.ccid,
                          product.parentSku,
                          product,
                          $event
                        )
                      "
                      :model-value="selectedQty[index]"
                      ref="selectedCartQty4"
                      append-inner-icon="mdi-plus"
                      item-value="qty"
                      item-title="qty"
                      item-children="qty"
                    ></v-select>
                  </v-hover>
                </v-col>
              </v-row>
              <v-row align="center" v-else class="mt-1">
                <v-col cols="auto" class="pr-1 py-0">
                  <p class="fontsize-14 mb-0 pb-7">Qty.</p>
                </v-col>
                <v-col class="pl-0">
                  <v-hover v-slot="{ isHovering, props }">
                    <v-select
                      v-model="selectedQty[index]"
                      :model-value="selectedQty[index]"
                      :items="
                        numberOptions(
                          product.customAttributes.baMinSalesQty,
                          product.customAttributes.baQtyIncrements,
                          product.qty,
                          index
                        )
                      "
                      class="word-break fontsize-14 line-height-21 ls-n009 font-weight-400 cartproductlist-select-button cart-selection-box select-change-icon"
                      :class="isHovering ? 'text-primary' : 'text-black'"
                      single-line
                      @update:modelValue="
                        updateToCart(
                          product.sku,
                          product.itemId,
                          index,
                          product.price,
                          product.personalizeDetails,
                          product.productOption,
                          product.customAttributes.letter,
                          product.productType,
                          product.customAttributes.isPersonalize,
                          product.customAttributes.builderVersion,
                          product.ccid,
                          product.parentSku,
                          product,
                          $event
                        )
                      "
                      :menu-props="{
                        auto: true,
                        overflowY: false,
                        zIndex: 9999,
                        backgroundColor: `#fff`,
                      }"
                      ref="selectedCartQty2"
                      append-inner-icon="mdi-plus"
                      v-bind="props"
                      density="compact"
                      variant="outlined"
                    ></v-select>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row class="pt-5 pb-10">
                <v-col cols="auto" class="py-0 pr-0">
                  <p class="fontsize-14 mb-1">Qty.</p>
                </v-col>
                <v-col cols="auto" class="py-0 pl-2">
                  <p class="fontsize-14 text-black">{{ product.qty }}</p>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-row class="ma-0 pb-2 pt-0" v-show="checkOut">
            <v-col align="right">
              <a
                class="fontsize-14 text-black text-uppercase cartproductlist-action-link cursor-pointer"
                @click.stop="
                  editProduct(
                    product.customAttributes,
                    product.sku,
                    product.itemId,
                    index,
                    product.customAttributes.isPersonalize,
                    product.customAttributes.builderVersion,
                    product.ccid,
                    product.productType,
                    product.parentSku,
                    product
                  )
                "
                >Edit</a
              >
              <span class="px-1">|</span>
              <a
                class="fontsize-14 text-black text-uppercase cartproductlist-action-link cursor-pointer"
                @click.stop="
                  remove(
                    product.sku,
                    product.itemId,
                    index,
                    product.customAttributes.builderVersion,
                    product
                  )
                "
                >Remove</a
              >
              <template v-if="builderSession(product)">
                <span class="px-1">|</span>
                <a
                  class="fontsize-14 text-black text-uppercase cartproductlist-action-link cursor-pointer"
                  @click.stop="saveForLater({ product })"
                  >Save for later</a
                >
              </template>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-divider
            class="primary"
            v-show="cartProductPage && cartItems.length > 1"
          ></v-divider>
        </v-row>
      </v-card>
      <v-col class="text-center mb-12">
        <v-hover v-slot:default="{ hover }">
          <router-link
            :class="hover ? 'content-link-hover-color' : 'text-primary'"
            class="text-decoration-none cartproductlist-link"
            to
            @click="closeDrawer()"
            >View and edit cart</router-link
          >
        </v-hover>
      </v-col>
    </v-col>
    <v-dialog
      v-model="confirmationDialogFlag"
      origin="left top"
      hide-overlay
      persistent
      max-width="800px"
    >
      <v-card tile flat>
        <v-card-title class="pb-0">
          <v-row no-gutters>
            <v-col cols="11">
              <p class="body mt-1 mb-3 fontcolor-black-light delete-title-font">
                Are you sure you would like to remove this item from the
                shopping cart?
              </p>
            </v-col>
            <v-col cols="1" class="text-right">
              <router-link
                to
                class="text-decoration-none black--text fontcolor-black-light"
              >
                <div @click="confirmationDialogFlag = false">X</div>
              </router-link>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="px-4 pt-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-btn
                class="text-uppercase ma-2"
                color="primary"
                variant="flat"
                rounded="0"
                @click="confirmationDialogFlag = false"
                >CANCEL</v-btn
              >
              <v-btn
                class="text-uppercase ma-2"
                color="error"
                variant="flat"
                rounded="0"
                @click="removeProduct(true)"
              >
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- sign in popup -->
    <v-dialog v-model="signInForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign In</p>
          <v-btn
            @click="closeSignInForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signInMessage">{{
            signInMessage
          }}</v-alert>
          <v-form ref="loginForm" v-model="validLogin">
            <v-col class="pt-3 pb-1">
              <v-text-field
                v-model="emailLogin"
                type="email"
                label="Email Address"
                ref="email"
                name="email"
                variant="outlined"
                density="comfortable"
                :rules="commonValidationRules.requiredRules"
              ></v-text-field>
            </v-col>
            <v-col class="pt-1 pb-0">
              <v-text-field
                v-model="passwordLogin"
                label="Password"
                ref="password"
                type="password"
                name="password"
                variant="outlined"
                density="comfortable"
                :rules="commonValidationRules.requiredRules"
              ></v-text-field>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                :disabled="inProgress"
                @click="signIn()"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress"
                ></v-progress-circular>
                &nbsp;SIGN IN
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignUpDialog()"
                >Sign Up</span
              >
            </v-col>
            <v-col cols="6" class="py-5 pr-7" align="right">
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Sign Up -->
    <v-dialog v-model="signUpForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign Up</p>
          <v-btn
            @click="closeSignUpForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signUpMessage">{{
            signUpMessage
          }}</v-alert>
          <v-form ref="signUpForm" id="signup-form" @submit.prevent="signUp">
            <v-text-field
              class="pt-2"
              label="First Name"
              type="text"
              ref="firstName"
              name="firstName"
              variant="outlined"
              density="comfortable"
              v-model="firstname"
              :rules="firstNameRules"
            ></v-text-field>
            <v-text-field
              class="pt-2"
              label="Last Name"
              type="text"
              ref="lastName"
              name="lastName"
              variant="outlined"
              density="comfortable"
              v-model="lastname"
              :rules="lastNameRules"
            ></v-text-field>

            <v-text-field
              class="pt-2"
              label="Email Id"
              type="email"
              ref="email"
              name="email"
              id="userEmail"
              variant="outlined"
              density="comfortable"
              v-model="email"
              :rules="signUpEmailRules"
            ></v-text-field>

            <v-text-field
              class="pt-2 mt-0"
              type="password"
              label="Password"
              ref="password"
              name="password"
              variant="outlined"
              density="comfortable"
              v-model="password"
              :rules="commonValidationRules.passwordRule"
            ></v-text-field>
            <v-checkbox
              v-model="newsletter"
              label="Subscribe to our newsletter"
              hide-details="true"
              color="indigo"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                :disabled="inProgress"
                @click="signUp(product)"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress"
                ></v-progress-circular>
                &nbsp;SIGN UP
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignInDialog()"
                >Sign In</span
              >
            </v-col>
            <v-col cols="6" class="py-5 pr-7" align="right">
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const storeFrontUrl = import.meta.env.VITE_APP_CRANE_DAILY_URL;
const retailorId = import.meta.env.VITE_APP_RETAILOR_ID;
import {
  getLable,
  customAttributeValue,
  priceFormatter,
  checkPromotion,
  googleAanalyticsEventRemoveCart,
  googleAanalyticsEventSaveForLatter,
  commonValidationRules,
  handleBeforeUnload,
  isoStringToNormalDate
} from "@/services/util.service";
import { doFetchProductTags } from "@/services/product.service";

import { getPartPricByStructure } from "@/services/getSessionItemsPricerObject.service.js"
import { pricerFieldsIdx, pricerFiles } from "crane-pricer"

import { getDomain } from "@/services/auth";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

import { createHelpers } from "vuex-map-fields";
const { mapFields: mapFieldsAuth } = createHelpers({
  getterType: "auth/getField",
  mutationType: "auth/updateField",
});
const { mapFields: registerInfo } = createHelpers({
  getterType: "registration/getField",
  mutationType: "registration/updateField",
});
export default {
  name: "CartProductList",
  props: ["cartProductPage", "checkOut"],
  data: () => ({
    commonValidationRules,
    priceFormatter: priceFormatter,
    isActive: true,
    selectedQty: [],
    selectedQtyPersonalize: "",
    retailorId: "08c43bbf-3735-4621-85f9-d71dc2cfb41b",
    itemNumber: "193C",
    quantity: "25",
    savFromKey: "Ub07GxNhS3yyoDfO",
    ccId: "766a5cef-6b51-4ab4-9f25-6446c4c8baeb",
    confirmationDialogFlag: false,
    confirmation: {
      confirmationMessage: "",
    },
    blue: "primary",
    white: "white",
    dialog: false,
    productId: "",
    currentProduct: null,
    removeSessionId: "",
    userQty: 0,
    productBuilderVersion: "",
    productDetails: "",

    signUpForm: false,
    signInForm: false,
    valid: true,
    validLogin: false,
    lazy: false,
    firstNameRules: [
      (v) => !!v || "First Name is required",
      (v) => (v && v.length >= 3) || "First Name must be at least 3 characters",
    ],
    lastNameRules: [
      (v) => !!v || "Last Name is required",
      (v) => (v && v.length >= 3) || "Last Name must be at least 3 characters",
    ],
    signUpEmailRules: [
      (v) => !!v || "Email Id is required",
      (v) => /.+@.+\..+/.test(v) || "Email Id must be valid",
    ],
  }),
  computed: {
    ...mapFieldsAuth({
      oneLogin: "one",
      emailLogin: "one.email",
      passwordLogin: "one.password",
      errorMessage: "errorMessage",
      inProgress: "inProgress",
      signInMessage: "signInMessage",
    }),
    ...registerInfo([
      "one",
      "one.firstname",
      "one.lastname",
      "one.newsletter",
      "one.email",
      "one.password",
      "signUpMessage",
    ]),
    builderSessionId: function () {
      return this.$route.params.sessionId;
    },
    sessionOneDetails: function () {
      return this.$store.state.sessionOne.one;
    },
    cartItems() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductList;
      } else {
        return this.$store.state.cartProduct.cartProductList;
      }
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_APP_IMAGE_PATH_URL;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return this.$store.state.guestInfo.guestQuoteId;
    },
    cartId: function () {
      return this.$route.params.designerMode;
    },
    cartItemEdit: function () {
      if (this.cartId && this.cartId !== "true") return true;
      return false;
    },
    /*productAttributes: function() {
      return this.$store.state.productOne.productAttributes;
    },*/
    productAttributes() {
      return this.$store.state.productList.attributeList.items;
    },
    isGateModulePage: function () {
      let pagePath = this.$route.path;
      let isGateModulePage = pagePath.includes("/gate/");
      if (isGateModulePage) return true;

      return false;
    },
    cartProductsProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductsProgress;
      } else {
        return this.$store.state.cartProduct.cartProductsProgress;
      }
    },
    fetchPriceInProgress: function () {
      return this.$store.state.productOne.fetchPriceInProgress;
    },
    basicInfo() {
      return this.$store.state.accountOne.one;
    },
  },
  methods: {
    async saveForLater({ product }) {
      try {
        let builderSessionOne = null;
        this.currentProduct = product;
        this.cartProductsProgress = true;
        if (this.userAuth) {
          if (
            product &&
            product.options &&
            product.options.options &&
            product.options.options.length > 0
          ) {
            let optionsList = product.options.options;
            builderSessionOne = optionsList.find(
              (x) => x && x.label && x.label.toLowerCase() == "buildersessionid"
            );

            if (builderSessionOne) {
              let sessionId = builderSessionOne.optionValue;
              if (sessionId) {
                await this.$store.dispatch("sessionOne/updateOne", {
                  sessionId: sessionId,
                  property: {
                    orderState: null,
                    quoteData: null,
                    state: "saved",
                  },
                });

                await this.$store.dispatch(
                  "userCartProduct/removeCartProduct",
                  {
                    productId: product.itemId,
                  }
                );
                googleAanalyticsEventRemoveCart("remove_from_cart", product);
                googleAanalyticsEventSaveForLatter(product, "saved_for_later");

                this.snackbar = {
                  message: "Product Added Successfully in Saved For Later",
                  color: "success",
                  show: true,
                  timeout: 3000,
                };
                this.$emit("actionClose", this.snackbar);
                window.location.reload();
              }
            }
          }
        } else {
          this.signInForm = true;
        }
      } catch (error) {
        console.error("Error occured : " ,error);
        this.$snotify.error(error.message, {
          position: "rightTop",
          showProgressBar: false,
        });
      }
    },
    async signIn() {
      try {
        this.cartProductsProgress = true;
        this.inProgress = true;
        const { valid } = await this.$refs.loginForm.validate();
        if (valid) {
          this.$store.dispatch("auth/changeRedirectFlag", false);
          let status = await this.$store.dispatch("auth/login");
          if (status) {
            this.$store.dispatch("auth/changeRedirectFlag", true);
            await this.$store.dispatch("accountOne/basicInfo");
            let builderSessionOne = null;
            if (
              this.currentProduct &&
              this.currentProduct.options &&
              this.currentProduct.options.options &&
              this.currentProduct.options.options.length > 0
            ) {
              let optionsList = this.currentProduct.options.options;
              builderSessionOne = optionsList.find(
                (x) => x && x.label && x.label.toLowerCase() == "buildersessionid"
              );

              if (builderSessionOne) {
                await this.$store.dispatch(
                  "userCartProduct/cartProductList",
                  builderSessionOne.optionValue
                );
                if (this.basicInfo) {
                  await this.$store.dispatch("sessionOne/updateOne", {
                    sessionId: builderSessionOne.optionValue,
                    property: {
                      state: "saved",
                      orderState: null,
                      quoteData: null,
                      userData: {
                        customer_id: this.basicInfo.id,
                        customer_name: this.basicInfo.firstname,
                      },
                    },
                  });
                  googleAanalyticsEventSaveForLatter(
                    this.currentProduct,
                    "saved_for_later"
                  );
                }

                this.signInForm = false;
                window.location.reload();
              }
            }
          }
          this.inProgress = false;
        }
        this.cartProductsProgress = false;
        this.inProgress = false;
      } catch (err) {
        this.cartProductsProgress = false;
        this.inProgress = false;
        console.error(err);
        this.$snotify.error(err.message, {
          position: "rightTop",
          showProgressBar: false,
        });
      }
    },
    async signUp() {
      try {
        const { valid } = await this.$refs.signUpForm.validate();
        if (valid) {
          try {
            this.$store.dispatch("auth/changeRedirectFlag", false);
            let status = await this.$store.dispatch("registration/signUp");
            if (status) {
              this.$store.dispatch("auth/changeRedirectFlag", false);
              await this.$store.dispatch("accountOne/basicInfo");
              let builderSessionOne = null;
              if (
                this.currentProduct &&
                this.currentProduct.options &&
                this.currentProduct.options.options &&
                this.currentProduct.options.options.length > 0
              ) {
                let optionsList = this.currentProduct.options.options;
                builderSessionOne = optionsList.find(
                  (x) => x && x.label && x.label.toLowerCase() == "buildersessionid"
                );
                if (builderSessionOne) {
                  await this.$store.dispatch(
                    "userCartProduct/cartProductList",
                    builderSessionOne.optionValue
                  );
                  if (this.basicInfo) {
                    await this.$store.dispatch("sessionOne/updateOne", {
                      sessionId: builderSessionOne.optionValue,
                      property: {
                        state: "saved",
                        orderState: null,
                        quoteData: null,
                        userData: {
                          customer_id: this.basicInfo.id,
                          customer_name: this.basicInfo.firstname,
                        },
                      },
                    });
                  }
                  this.signUpForm = false;
                  this.signInForm = false;
                  window.location.reload();
                }
              }
            }
          } catch (err) {
            console.error(err);
            this.$snotify.error(err.message, {
              position: "rightTop",
              showProgressBar: false,
            });
          }
        }
      } catch (error) {
        console.error("Error occured : " , error);
        this.$snotify.error(error.message, {
          position: "rightTop",
          showProgressBar: false,
        });
      }
    },
    closeSignInForm() {
      this.signInForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    closeSignUpForm() {
      this.signUpForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    openSignInDialog() {
      this.signInForm = true;
      this.signUpForm = false;
    },
    openSignUpDialog() {
      this.signInForm = false;
      this.signUpForm = true;
    },
    builderSession(product) {
      let builderSessionOne = null;
      if (
        product &&
        product.options &&
        product.options.options &&
        product.options.options.length > 0
      ) {
        let optionsList = product.options.options;
        builderSessionOne = optionsList.find(
          (x) => x && x.label && x.label.toLowerCase() == "buildersessionid"
        );

        if (builderSessionOne) {
          return true;
        }
      }
    },
    getAttribute(customAttributes, attributeCode) {
      if (this.productAttributes) {
        let newValue = this.productAttributes.filter((elements) => {
          return elements.attributeCode === attributeCode;
        });
        if (newValue.length > 0) {
          let labelValue = newValue[0].options.filter((elementNew) => {
            return elementNew.value === customAttributes;
          });
          if (labelValue.length > 0) {
            return labelValue[0].label;
          }
        }
      }
    },
    checkThumbnails(personalizeDetails) {
      if (personalizeDetails) {
        let imgCount = 0;
        let i;
        let personalizeDetailsArray = JSON.parse(personalizeDetails);
        for (i = 0; i < personalizeDetailsArray.length; i++) {
          if (!personalizeDetailsArray[i].thumbnailUrl) imgCount++;
        }
        if (i == imgCount) return false;
        return true;
      }
      return false;
    },
    pageName() {
      return this.$route.name;
    },
    qtyPriceOptions(list, qty, index) {
      this.selectedQty[index] = Number(qty).toString();
      return JSON.parse(list);
    },
    numberOptions: function (baMinSalesQty, baQtyIncrements, qty, index) {
      if (baMinSalesQty && baQtyIncrements) {
        let minSalesQty = baMinSalesQty;
        let qtyIncrements = baQtyIncrements;
        minSalesQty = Number(minSalesQty) ? Number(minSalesQty) : 1;
        qtyIncrements = Number(qtyIncrements) ? Number(qtyIncrements) : 1;

        let nums = [];
        nums[0] = minSalesQty;
        if (qty) {
          this.selectedQty[index] = Number(qty);
        }
        for (let i = 1; i <= 98; i++) {
          minSalesQty = minSalesQty + qtyIncrements;
          nums[i] = minSalesQty;
        }
        if (nums.length > 0) return nums;
        else return "";
      } else {
        return "";
      }
    },
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },
    getCustomAttributeLable(customAttributes, attributeCode) {
      return getLable(customAttributes, attributeCode);
    },
    gateViewEditCart() {
      this.closeDrawer();
      window.open(`/cart-products/`, "_self");
    },
    closeDrawer() {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
      window.open(`${storeFrontUrl}/cart-products/`, "_self");
    },
    goToProductDetails(customAttributes, sku, productType) {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
      let productPath = customAttributes.urlKey
        ? customAttributes.urlKey
        : customAttributeValue(customAttributes, "url_key");
      if (productType == "configurable") {
        window.open(`${storeFrontUrl}/${productPath}`, "_self");
      } else {
        window.open(`${storeFrontUrl}/${productPath}`, "_self");
      }
    },
    remove(sku, itemId, index, builderVersion, product) {
      this.productId = itemId;
      this.productDetails = product;

      let builderSessionOne = this.getBuilderSessionValueFromProduct(product);
      this.removeSessionId =
        builderSessionOne && builderSessionOne.value
          ? builderSessionOne.value
          : "";

      this.productBuilderVersion = builderVersion;
      this.userQty = this.selectedQty[index];
      (this.confirmationDialogFlag = true),
        (this.confirmation = {
          confirmationMessage:
            "Are you sure you would like to remove this item from the shopping cart?",
        });
    },
    editProduct(
      customAttributes,
      sku,
      itemId,
      index,
      isPersonalize,
      builderVersion,
      ccid,
      productType,
      parentSku,
      product
    ) {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
      if (isPersonalize == "1") {
        if (builderVersion == "2.0" || !ccid) {
          let builderSessionOne =
            this.getBuilderSessionValueFromProduct(product);
          if (builderSessionOne) {
            this.$router.push({
              name: "SessionResume",
              params: {
                sessionId: builderSessionOne.value,
              },
            });
          }
        } else {
          let url = `${storeFrontUrl}/crane-connection/${retailorId}/${this.selectedQty[index]}/${sku}/${ccid}/${itemId}`;
          window.open(url, "_self");
        }
      } else {
        let productPath = customAttributes.urlKey
          ? customAttributes.urlKey
          : customAttributeValue(customAttributes, "url_key");
        this.$emit("actionEditMessage");
        window.open(
          `${storeFrontUrl}/${productPath}?qty=${this.selectedQty[index]}&itemId=${itemId}`,
          "_self"
        );
      }
    },
    async updateToCart(
      sku,
      itemId,
      index,
      price,
      personalizeDetails,
      optionId,
      optionValue,
      productType,
      isPersonalize,
      builderVersion,
      ccid,
      parentSku,
      product
    ) {
      let vm = this;
      if (this.userAuth) {
        if (isPersonalize == "1") {
          // user builder product
          if (builderVersion == "2.0" && itemId && price && product) {
            this.$store.commit("userCartProduct/cartProductsProgress", true);
            const quantity = this.selectedQty[index];
            let updatedPriceData = await this.getPersonalizedPrice(
              product,
              quantity,
              sku
            );
            await this.updateToCartItem(
              null,
              sku,
              quantity,
              itemId,
              this.$route.name,
              updatedPriceData.subTotal,
              updatedPriceData.personalizeDetails,
              true,
              builderVersion,
              updatedPriceData.sessionId
            );
          } else {
            // user  cc product
            try {
              const quantity = this.selectedQty[index];
              let response = await this.$store.dispatch(
                "userCartProduct/updateToCConectionCart",
                {
                  sku: sku,
                  qty: quantity,
                  ccid: ccid,
                }
              );
              if (response) {
                await this.updateToCartItem(
                  ccid,
                  sku,
                  quantity,
                  itemId,
                  this.$route.name,
                  null,
                  null,
                  true
                );
              }
            } catch (error) {
              setTimeout(() => {
                this.$snotify.error(error.message, {
                  position: "rightTop",
                  showProgressBar: false,
                });
              }, 3000);
            }
          }
        } else if (
          productType == "configurable" &&
          itemId &&
          optionId &&
          optionValue
        ) {
          // configurable product user
          let quantity = vm.selectedQty[index];
          try {
            await this.$store.dispatch(
              "userCartProduct/updateToConfiguredCart",
              {
                ccid: null,
                sku: parentSku,
                qty: quantity,
                itemId: itemId,
                routeName: this.$route.name,
                optionId: optionId.attributesInfo[0].optionId,
                optionValue: optionValue,
              }
            );
            this.$snotify.success("Product Updated Successfully", {
              position: "rightTop",
              showProgressBar: false,
            });
          } catch (error) {
            setTimeout(() => {
              this.$snotify.error(error.message, {
                position: "rightTop",
                showProgressBar: false,
              });
            }, 3000);
          }
        } else {
          // simple product user
          let quantity = vm.selectedQty[index];
          await this.updateToCartItem(
            null,
            sku,
            quantity,
            itemId,
            this.$route.name,
            null,
            null,
            true
          );
        }
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      }
      // GUEST Code start
      else {
        if (isPersonalize == "1") {
          // builder product guest
          if (builderVersion == "2.0" && itemId && price && product) {
            this.$store.commit("cartProduct/cartProductsProgress", true);
            const quantity = this.selectedQty[index];
            let updatedPriceData = await this.getPersonalizedPrice(
              product,
              quantity,
              sku
            );
            await this.updateToCartItem(
              null,
              sku,
              quantity,
              itemId,
              this.$route.name,
              updatedPriceData.subTotal,
              updatedPriceData.personalizeDetails,
              false,
              builderVersion,
              updatedPriceData.sessionId
            );
          } else {
            // cc product  guest
            try {
              const quantity = this.selectedQty[index];
              let response = await this.$store.dispatch(
                "cartProduct/updateToCConectionCart",
                {
                  sku: sku,
                  qty: quantity,
                  ccid: ccid,
                }
              );
              if (response) {
                await this.updateToCartItem(
                  ccid,
                  sku,
                  quantity,
                  itemId,
                  this.$route.name,
                  null,
                  null,
                  false
                );
              }
            } catch (err) {
              setTimeout(() => {
                this.$snotify.error(err.message, {
                  position: "rightTop",
                  showProgressBar: false,
                });
              }, 3000);
            }
          }
        } else if (
          productType == "configurable" &&
          itemId &&
          optionId &&
          optionValue
        ) {
          // configurable product user
          let quantity = this.selectedQty[index];
          try {
            await this.$store.dispatch("cartProduct/updateToConfiguredCart", {
              ccid: null,
              sku: parentSku,
              qty: quantity,
              userQuote:
                this.$store.state.userCartProduct.cartProductDetails.id,
              itemId: itemId,
              routeName: this.$route.name,
              optionId: optionId.attributesInfo[0].optionId,
              optionValue: optionValue,
            });
            this.$snotify.success("Product Updated Successfully", {
              position: "rightTop",
              showProgressBar: false,
            });
          } catch (error) {
            setTimeout(() => {
              this.$snotify.error(error.message, {
                position: "rightTop",
                showProgressBar: false,
              });
            }, 3000);
          }
        } else {
          // simple product user
          let quantity = this.selectedQty[index];
          await this.updateToCartItem(
            null,
            sku,
            quantity,
            itemId,
            this.$route.name,
            null,
            null,
            false
          );
        }
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
    },
    async removeProduct(action) {
      // eslint-disable-next-line

      //let token = getAuthUser();
      try {
        this.confirmationDialogFlag = false;
        if (action) {
          if (this.userAuth) {
            try {
              await this.$store.dispatch("userCartProduct/removeCartProduct", {
                productId: this.productId,
              });
              googleAanalyticsEventRemoveCart(
                "remove_from_cart",
                this.productDetails
              );

              this.$snotify.success("Product Removed Successfully", {
                position: "rightTop",
                showProgressBar: false,
              });
              this.$store.dispatch("userCartProduct/cartDrawer", false);
            } catch (error) {
              this.$snotify.error(error.message, {
                position: "rightTop",
                showProgressBar: false,
              });
            }
          } else {
            try {
              await this.$store.dispatch(
                "cartProduct/removeCartProduct",
                this.productId
                //guestQuoteId: this.guestQuoteId
              );
              googleAanalyticsEventRemoveCart(
                "remove_from_cart",
                this.productDetails
              );
              this.$snotify.success("Product Removed Successfully", {
                position: "rightTop",
                showProgressBar: false,
              });

              this.$store.dispatch("cartProduct/cartDrawer", false);
            } catch (error) {
              this.$snotify.error(error.message, {
                position: "rightTop",
                showProgressBar: false,
              });
            }
          }

          //this.dialog = false;
          cookies.remove(
            this.productId,
            null,
            getDomain(window.location.hostname)
          );
          try {
            if (this.removeSessionId && this.productBuilderVersion == "2.0") {
              await this.$store.dispatch("sessionOne/updateOne", {
                sessionId: this.removeSessionId,
                property: {
                  quoteData: null,
                  orderState: null,
                },
              });
            }
          } catch (error) {
            this.$snotify.error(error.message, {
              position: "rightTop",
              showProgressBar: false,
            });
          }

          if (
            this.removeSessionId == this.builderSessionId &&
            this.productBuilderVersion == "2.0"
          ) {
            this.$router.push({
              name: "SessionStart",
              params: {
                sku: this.productDetails.sku,
                quantity: this.userQty,
              },
            });
          }
        }
      } catch (error) {
        console.error(error)
        this.$snotify.error(error, {
          position: "rightTop",
          showProgressBar: false,
        });
      }
      this.removeSessionId = "";
      this.productBuilderVersion = "";
      this.confirmation = {
        confirmationMessage: "",
      };
      this.productId = "";
    },
    goToRoute(sessionId) {
      if (sessionId) {
        this.$router.push({
          name: "SessionResume",
          params: {
            sessionId: sessionId,
          },
        });
      }
    },
    getBuilderSessionValueFromProduct(product) {
      let builderSessionOne = null;
      if (
        product &&
        product.options &&
        product.options.options &&
        product.options.options.length > 0
      ) {
        let optionsList = product.options.options;
        builderSessionOne = optionsList.find(
          (x) => x && x.label && x.label.toLowerCase() == "buildersessionid"
        );
      }
      return builderSessionOne;
    },
    async getPersonalizedPrice(product, qty, sku) {
      try {
        let productSummaryDetails = [];
        let builderSessionOne = this.getBuilderSessionValueFromProduct(product);
        let sessionId = null;
        if (builderSessionOne) {
          sessionId = builderSessionOne.value;
          if (!sessionId) {
            this.$snotify.error("Error while fetching product", {
              position: "rightTop",
              showProgressBar: false,
            });
            throw "Session id is not present";
          }
          let resp = await this.$store.dispatch("sessionItemList/fetchList", {
            sessionId,
          });
          if (resp && resp.response) {
            for (let r = 0; r < resp.response.length; r++) {
              let item = resp.response[r];
              if (item.summaryData) {
                let parsedJson = JSON.parse(item.summaryData);
                productSummaryDetails.push(parsedJson);
              }
            }
          }
        }

        let partPrice = null;
        let subTotal = 0;

        let productPartsObjects = [];
        this.isPromotionApplied = false;
        let imagelibProduct = await doFetchProductTags(sku);
        for (let i = 0; i < productSummaryDetails.length; i++) {
          let productPart = productSummaryDetails[i];
          if (productPart.partSelected) {
            productPartsObjects[i] = {};
            productPartsObjects[i].partName = productPart.name;
            productPartsObjects[i].processes = productPart.processes;
            productPartsObjects[i].linerObject = productPart.linerObject;
          }
        }
        let response = await checkPromotion({
          sku: sku,
          quantity: qty,
          product: imagelibProduct ? imagelibProduct : {},
          productPartsObjects: productPartsObjects,
        });
        if (response) {
          if (response.exclusiveRules.length !== response.exclusive.length)
            this.isPromotionApplied = false;
          else {
            if (response.appliedRules.length) {
              this.isPromotionApplied = true;
              let sortedPromotions = [];
              for (let i = 0; i < response.appliedRules.length; i++) {
                let promotion = response.appliedRules[i];
                if (promotion.price_adjustment == "fixed") {
                  sortedPromotions.unshift(promotion);
                } else {
                  sortedPromotions.push(promotion);
                }
              }
              //fixed is on top
              for (let r = 0; r < sortedPromotions.length; r++) {
                let sortedPromotion = sortedPromotions[r];
                if (sortedPromotion.price_adjustment == "fixed") {
                  subTotal = sortedPromotion.price_adjustment_value;
                } else if (sortedPromotion.price_adjustment == "bump_amount") {
                  subTotal += sortedPromotion.price_adjustment_value;
                }
              }
              if (subTotal == 0) this.isPromotionApplied = false;
            } else {
              this.isPromotionApplied = false;
            }
          }
        }

        let isPriceEngineFailed = false;

        if (!this.isPromotionApplied) {
          subTotal = 0;
          let allErrors = []
          for (let i = 0; i < productSummaryDetails.length; i++) {
            isPriceEngineFailed = false;
            let part = productSummaryDetails[i];
            if (part.partSelected) {
                try {
                  let options = {
                    pricingDateTime: Date.now(),
                    fallbackToOMS: false,
                    compareToOMS: false,
                    audit: [],
                  };
                  let resp = null
                  if (part && part.partStructure?.elements){
                    console.log("Product has partStructure ")
                    resp = await getPartPricByStructure({ pricer: "cloud", qty: qty, sessionItem: part, partStructure: part.partStructure, options })
                  }else {
                    console.log("Product has orderObject ")
                    part.orderObject.Details[0].Quantity = qty;
                    part.orderObject.Details[0].Sublines.forEach((subline) => {
                      subline.Quantity = qty;
                    });
                    let newObj = JSON.parse(JSON.stringify(part.orderObject));
                    resp = await getPartPricByStructure({ orderPriceObject: newObj, sessionItem: part, pricer: "cloud", options });
                  }
                  
                  part.partPrice = 0
                  if(resp.skuBasedError && resp.skuBasedError.length){
                    allErrors.push(...resp.skuBasedError)
                    this.saveIncident({
                      errorObject: {
                        stack: {
                          error: resp.skuBasedError.toString()
                        },
                      },
                      title: "Personalize api pricing error",
                      description:
                        "Price api error for poduct sku " +
                        sku +
                        " and part name " +
                        part.name +
                        " with quantity " +
                        qty,
                      tags: ["price_api_error"],
                      sessionId,
                      toContinueSession: true,
                    })
                  }
                  else if(resp.newPrice){
                    part.partPrice = resp.newPrice
                  } else if(options.fallbackToOMS && !resp.newPrice){
                    part.partPrice = resp.oldPrice
                  }

                  subTotal += part.partPrice;
                } catch (error) {
                  console.log(error);
                  this.$snotify.error("Error while fetching price", {
                    position: "rightTop",
                    showProgressBar: false,
                  });
                }

                // updating summary json part price for current item
                if(part.orderObject && part.orderObject.Details[0]){
                  part.orderObject.Details[0].partPrice = part.partPrice;
                }

                part.quantity = qty;
                try {
                  await this.$store.dispatch(
                    "sessionItemOne/updateItemProperties",
                    {
                      sessionId: sessionId,
                      productPartId: part.id,
                      surfaceType: part.currentPartSurfaceType,
                      surfaceId:
                        part.currentPartSurfaceType === "surface"
                          ? part.surfaceId
                          : part.alternateSurfaceId,
                      property: {
                        summaryData: JSON.stringify(part),
                      },
                    }
                  );
                } catch (err) {
                  console.log(err);
                  throw err;
                }              
            }
          }
          if(allErrors.length){
            allErrors = [...new Set(allErrors)]
            for(let e=0; e<allErrors.length; e++){
              this.$snotify.error(allErrors[e],{
                position: "rightTop",
                showProgressBar: false,
              });
            }
          }
        }

        return {
          subTotal: subTotal,
          personalizeDetails: null,
          sessionId,
        };
      } catch (error) {
        this.$snotify.error("Cloud communication error, please try again", {
          position: "rightTop",
          showProgressBar: false,
        });
        console.error("error", error);
      }
    },
    async saveIncident({
      errorObject,
      title,
      description,
      tags,
      sessionId,
      toContinueSession = false,
    }) {
      try {
        await this.$store.dispatch("incidentOne/setOne");
        let userAgent = navigator;

        let currentdate = await isoStringToNormalDate();
        let newErr = null;
        if (errorObject && errorObject.stack && errorObject.stack.new_price) {
          newErr = JSON.stringify(errorObject);
        } else {
          newErr = JSON.stringify(errorObject.toLocaleString());
        }

        let incidentObj = {
          title: title,
          level: "major",
          description: description,
          tags: tags && tags.length ? tags : [],
          recorded_at: currentdate,
          module: "studio",
          metadata: {
            currentSessionId: sessionId,
            errorStack: JSON.stringify(errorObject.stack || errorObject),
            error: newErr,
            platform: userAgent.platform,
            appVersion: userAgent.appVersion,
            appCodeName: userAgent.appCodeName,
          },
        };
        let savedIncidentResp = await this.$store.dispatch("incidentOne/save", {
          one: incidentObj,
        });
        console.log("savedIncidentResp", savedIncidentResp);

        let ddErr = new Error(title)
        console.error(ddErr, incidentObj.metadata)
        // if(window.DD_RUM){
        //   window.DD_RUM.onReady(function() {
        //     window.DD_RUM.addError(ddErr,{
        //       module: "studio",
        //       level: "major",
        //       currentSessionId: sessionId,
        //       description,
        //       errorDetails: newErr
        //     });
        //   });
        // } else {
        //   console.log("window.DD_RUM  variable is NOT Available in studio ")
        // }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async updateToCartItem(
      ccid,
      sku,
      qty,
      itemId,
      routeName,
      price,
      personalizeDetails,
      isUser,
      builderVersion,
      sessionId
    ) {
      // eslint-disable-next-line
      try {
        let resp = null;
        if (isUser) {
          resp = await this.$store.dispatch("userCartProduct/updateMiniCart", {
            ccid: ccid,
            sku: sku,
            qty: qty,
            itemId: itemId,
            routeName: routeName,
            price: price,
            personalizeDetails: personalizeDetails,
          });
        } else {
          resp = await this.$store.dispatch("cartProduct/updateMiniCart", {
            ccid: ccid,
            sku: sku,
            qty: qty,
            itemId: itemId,
            routeName: routeName,
            price: price,
            personalizeDetails: personalizeDetails,
          });
        }
        if (resp && builderVersion == "2.0") {
          let sessionOneDetails = await this.$store.dispatch(
            "sessionOne/fetchOne",
            { sessionId }
          );

          if (sessionOneDetails && sessionOneDetails.response)
            sessionOneDetails = sessionOneDetails.response;

          await this.$store.dispatch("sessionOne/updateOne", {
            sessionId: sessionId,
            property: {
              productData: {
                ...sessionOneDetails.productData,
                quantity: qty,
                productSubTotal: price,
              },
              quoteData: {
                ...sessionOneDetails.quoteData,
                qty,
                price: resp.price, // single note price
                productSubTotal: price,
              },
            },
          });
        }
        this.$snotify.success("Product Updated Successfully", {
          position: "rightTop",
          showProgressBar: false,
        });

        if (this.builderSessionId == sessionId) {
          window.removeEventListener("beforeunload", handleBeforeUnload);
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
        this.$snotify.error(error.message, {
          position: "rightTop",
          showProgressBar: false,
        });
      }
    },
  },
  async created() {
    let currentSessionSku = null
    if(this.sessionOneDetails && this.sessionOneDetails.sku){
      currentSessionSku = this.sessionOneDetails.productData.sku
    } else {
      let resp = await this.$store.dispatch("sessionOne/fetchOne", {
        sessionId: this.builderSessionId,
      });
      currentSessionSku = resp.response.productData.sku
    }
    if (this.cartItems && this.cartItems.length) {
      let skuList = [currentSessionSku];
      for (let p = 0; p < this.cartItems.length; p++) {
        let product = this.cartItems[p];
        if (product && product.options) {
          let resp = this.builderSession(product);
          if (resp) skuList.push(product.sku);
        }
      }
      if (skuList.length) {
        // pricer bundle loaded

        window.pricerFieldsIdx = pricerFieldsIdx;
        let currentDateInIso = new Date().toISOString()
        let bundleResp = await this.$store.dispatch(
          "pricerBundleOne/fetchPriceBundle",
          { skuList: skuList, availableAt: currentDateInIso }
        );
        if (bundleResp) {
          window.pricer = {
            ...pricerFiles,
            ...bundleResp,
          };
        }
        console.log(window.pricer);
      }
    }
  },
};
</script>
<style lang="scss">
.cartproductlist-select-button.v-text-field.v-text-field--enclosed:not(
    .v-text-field--rounded
  )
  > .v-input__control
  > .v-input__slot {
  padding: 0 8px !important;
  min-height: 30px !important;
  max-width: 100px !important;
}
.cartproductlist-select-button .v-input__append-inner {
  padding-bottom: 3px !important;
}
.cartproductlist-select-button .v-select__selection.v-select__selection--comma {
  margin: 0px !important;
}
.cartproductlist-select-button:hover
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.cartproductlist-select-button:hover
  .v-input__append-inner
  .v-input__icon
  .v-icon {
  color: #2850da !important;
}
.cartproductlist-expansion .v-expansion-panel-text__wrap {
  padding: 0 0px 0px !important;
}
.custom-panel-title > .v-expansion-panel-title__overlay {
  opacity: 0 !important;
}

.custom-panel-title:hover .v-expansion-panel-title__overlay {
  opacity: 0 !important;
}
.v-expansion-panel--active .v-expansion-panel-title {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 41px;
}
.custom-panel-text .v-expansion-panel-text__wrapper {
  padding-left: 0px !important;
}
.delete-title-font {
  font-size: 18px !important;
}
</style>
<style src="./CartProductList.scss" lang="scss" scoped/>