<template>
  <div>
    <div v-if="!designerModeFlag">
      <v-app-bar
        v-if="isMobileDevice && productPage"
        height="37"
        :absolute="!isScrolled"
        :app="isScrolled"
        :color="isScrolled ? 'text-white' : '#c7beac'"
        elevation="0"
        class="product-page-header-appbar"
        :class="isMobileDevice ? 'productpage-header-toolbar' : ''"
      >
        <v-row class="align-center ma-0 pb-0 d-sm-none">
          <v-col
            class="d-flex justify-center align-center pa-0 productpage-header productpage-header-vertical-line"
          >
            <v-app-bar-nav-icon
              @click.stop="menuAction()"
              class="z-index-100 productpage-app-bar-btn"
              color="primary"
            ></v-app-bar-nav-icon>
          </v-col>

          <v-col
            class="px-0 d-flex align-center justify-center productpage-header-vertical-line"
          >
            <v-btn icon x-small @click.stop="undoOperation" disabled>
              <img
                v-if="undoLength == 0"
                :src="getImagesForUndoRedo.undoInactive"
                height="18"
                width="18"
              />
              <img
                v-else-if="undoLength != 0"
                :src="getImagesForUndoRedo.undoActive"
                height="18"
                width="18"
              />
            </v-btn>
          </v-col>

          <v-col
            class="px-0 d-flex align-center justify-center productpage-header-vertical-line"
          >
            <v-btn icon x-small @click.stop="redoOperation" disabled>
              <img
                v-if="redoLength == 0"
                :src="getImagesForUndoRedo.redoInactive"
                height="18"
                width="18"
              />
              <img
                v-else-if="redoLength != 0"
                :src="getImagesForUndoRedo.redoActive"
                height="18"
                width="18"
              />
            </v-btn>
          </v-col>

          <v-col
            cols="3"
            class="pa-0 d-flex align-center text-center productpage-header-vertical-line select-min-width"
          >
            <v-select
              :items="partsList"
              class="word-break select-change-icon rounded-0 overflow-btn-icon productpage-select-input productpage-header-select"
              density="compact"
              hide-details
              ref="partMobileChangeButton"
              v-model="partSelected"
              @update:modelValue="changeCanvas()"
              bg-color="transparent"
              append-inner-icon="mdi-plus"
              :menu-props="{ class: 'productpage-part-select-menu' }"
            >
              <template v-slot:selection="{ item }">
                <span
                  class="pt-1 productpage-part-select-text text-uppercase text-primary regular-font fontsize-10 text-center rounded-0"
                >
                  {{ item && item.raw ? item.raw.text : "" }}</span
                >
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item
                  :class="
                    checkIsPartActive(partSelected, item)
                      ? 'productpage-part-select-menu--active'
                      : ''
                  "
                >
                  <v-list-item-title
                    v-bind="props"
                    class="text-uppercase text-primary regular-font fontsize-10 rounded-0 transparent-background cursor-pointer"
                  >
                    {{ item && item.raw ? item.raw.text : "" }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>

          <v-col cols="3" class="pa-0 text-center select-min-width">
            <v-select
              :items="selectedPartSurfaces"
              v-model="surfaceIdHeader"
              class="word-break select-change-icon rounded-0 overflow-btn-icon productpage-select-input productpage-header-select"
              density="compact"
              @update:modelValue="changePartSurface"
              attach
              hide-details
              flat
              return-object
              item-text="name"
              item-value="id"
              append-inner-icon="mdi-plus"
              :menu-props="{ class: 'productpage-part-select-menu' }"
            >
              <template v-slot:selection="{ item }">
                <span
                  class="pt-1 productpage-part-select-text text-uppercase text-primary regular-font fontsize-10 text-center rounded-0"
                  >{{ item && item.raw ? item.raw.name : "" }}</span
                >
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item
                  :class="
                    surfaceIdHeader.id == item.raw.id
                      ? 'productpage-part-select-menu--active'
                      : ''
                  "
                >
                  <v-list-item-title
                    v-bind="props"
                    class="text-uppercase text-primary regular-font fontsize-10 rounded-0 transparent-background cursor-pointer"
                  >
                    {{ item && item.raw ? item.raw.name : "" }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-app-bar>
      <div v-if="!isMobileDevice" class="d-flex justify-space-between align-center">
        <v-menu offset-y down allow-overflow v-if="userAuth">
          <template v-slot:activator="{ on }">
            <v-btn
              variant="text"
              v-on="on"
              class="d-none d-md-inline-block header-buttons mr-1 text-truncate"
              :max-width="$vuetify.display.mdOnly ? '50px' : ''"
            >
              <span class="text-uppercase">Hi {{ userName }}</span>
            </v-btn>
          </template>
          <v-card elevation="0" tile flat>
            <v-list dense flat>
              <v-list-item>
                <v-list-item-title
                  @click="goToDashboard()"
                  class="cursor-pointer"
                  >My Account</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="goToLogout()" class="cursor-pointer"
                  >Logout</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-btn
          variant="plain"
          :ripple="false"
          icon
          @click="goToLogin()"
          v-if="!userAuth"
          class="btn-plus-minus-opacity"
        >
          <v-icon class>mdi-account-circle-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          :class="textColor"
          class="header-buttons mr-n1 btn-plus-minus-opacity"
          @click.stop="showCartDrawer(true)"
          :max-width="$vuetify.display.mdOnly ? '35px' : ''"
          variant="plain"
        >
          <v-icon class="header-action-icons">mdi-cart-outline</v-icon>
          <span v-if="cartProductsProgress || inProgress">
            <v-progress-circular
              :size="20"
              color="primary"
              :width="2"
              indeterminate
            ></v-progress-circular>
          </span>
          <span v-else>
            <v-badge
              class="mb-4 badge-text-white"
              v-show="cartProductsItem.length"
              color="pink"
              :content="cartProductsItem.length"
            ></v-badge>
          </span>
        </v-btn>
      </div>
    </div>

    <vue-snotify />

    <!-- mobile view sidebar for category list and sign-in link -->
    <v-navigation-drawer
      v-model="showMenu"
      v-if="showMenu"
      width="320"
      :app="showMenu"
      :absolute="!showMenu"
      color="primary"
      class="z-index-1000"
      tile
    >
      <v-icon
        @click.stop="showMenu = !showMenu"
        class="text-white pa-4 pl-4 cursor-pointer"
        >mdi-close</v-icon
      >

      <v-row class="mt-12" justify="center" v-if="inProgress">
        <v-progress-circular
          :size="60"
          color="white"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-list v-else class="pl-4 mobile-header-list background-transparent">
        <v-list-group v-if="userAuth">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text-white text-uppercase h3 my-2"
                >Hi {{ userName }}</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item>
            <span
              class="text-white text-uppercase h4 my-2"
              @click="goToDashboard()"
              >My Account</span
            >
          </v-list-item>
          <v-list-item>
            <span
              @click="goToLogout()"
              class="text-white text-uppercase h4 my-2"
              >Logout</span
            >
          </v-list-item>
        </v-list-group>
        <v-list-item v-if="!userAuth">
          <v-list-item-content class="pa-0">
            <v-list-item-title
              class="text-white text-uppercase h3 my-2"
              @click="goToLogin()"
              >Sign-In</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- end of mobile view side bar -->

    <!-- cart list navigation drawer  -->
    <v-navigation-drawer
      v-model="cartDrawer"
      v-if="cartDrawer"
      :width="!isDesktop ? defaultWidth : productListWidth"
      color="#ffffff"
      v-resize="resizeCart"
      v-click-outside="outside"
      temporary
      class=""
      :class="
        this.$route.name == 'HomePage'
          ? '  headerpage-cart-drawer-top-56'
          : 'headerpage-cart-drawer'
      "
      location="right"
    >
      <v-row class="px-4 py-2" @click="stopPropogation">
        <v-col cols="7">
          <h2 class="black-text mt-3 text-left">Shopping Cart</h2>
        </v-col>
        <v-col cols="5" justify="center">
          <v-hover v-slot="{ isHovering, props }">
            <v-btn
              class="float-right mt-2"
              :variant="!isHovering ? 'flat' : 'outlined'"
              :color="hover ? 'white' : 'primary'"
              :class="
                hover ? 'text-primary header-closedrawer-button' : 'text-white'
              "
              depressed
              @click.stop="closeCartDrawer(false)"
              v-bind="props"
              :rounded="0"
              size="large"
            >
              CLOSE
              <v-icon
                :class="hover ? 'primary--text' : 'white--text'"
                class="h3"
                >mdi mdi-close</v-icon
              >
            </v-btn>
          </v-hover>
        </v-col>
      </v-row>
      <v-row v-show="cartProductsProgress || inProgress">
        <v-col class="my-12" align="center" justify="center">
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
      <div
        v-show="!cartProductsProgress && !inProgress"
        @click="stopPropogation"
      >
        <div v-show="cartProductsItem.length == 0" ref="defaultMsgDiv">
          <v-row>
            <v-col class="text-center pt-8 pb-16">
              <p class="black--text pb-0 mb-0 font-weight-medium">
                You have no items in your shopping cart.
              </p>
            </v-col>
          </v-row>
        </div>
        <div v-show="cartProductsItem.length !== 0">
          <div ref="checkoutButtonDiv">
            <v-row class="px-4 mt-2" v-if="stockFlag">
              <v-col cols="7">
                <p :class="isScrolled ? '' : 'black--text'" class="text-left">
                  {{ cartProductsItem.length }} Items
                </p>
              </v-col>
              <v-col cols="5" align="right">
                <p class="black--text mb-0">Cart Subtotal</p>
                <h2 :class="isScrolled ? '' : 'black--text'">
                  ${{ priceFormatter(cartProductTotalAmt.subtotal) }}
                </h2>
              </v-col>
            </v-row>
            <v-row class="px-4" v-if="stockFlag">
              <v-col cols="12">
                <v-hover v-slot="{ isHovering, props }">
                  <v-btn
                    :rounded="0"
                    size="large"
                    block
                    :variant="!isHovering ? 'flat' : 'outlined'"
                    class="text-uppercase ls-0 body mt-5 pb-1"
                    :class="!isHovering ? ' text-white' : 'text-primary'"
                    @click="goToCheckout()"
                    v-bind="props"
                    color="primary"
                    >GO TO CHECKOUT</v-btn
                  >
                </v-hover>
              </v-col>
            </v-row>
          </div>
          <v-list
            class="headerpage-cart-list mb-6"
            :height="productListHeight()"
          >
            <v-list-item>
              <v-col class="overflow-y-scroll">
                <CartProductList
                  :checkOut="chekoutFlag"
                  :cartProductsProgress="cartProductsProgress"
                  @actionEditMessage="showMessageOnPage"
                  @stockFlag="stockFlagHideShow"
                />
              </v-col>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
const storeFrontUrl = import.meta.env.VITE_APP_CRANE_DAILY_URL;
import {
  customAttributeValue,
  priceFormatter,
  surfaceIds,
} from "@/services/util.service";
import CartProductList from "@/components/cart-product/CartProductList.vue";
export default {
  name: "ProductPageHeader",
  components: {
    CartProductList,
  },
  props: [
    "partsList",
    "selectedPart",
    "redoLength",
    "undoLength",
    "partSurface",
    "selectedPartSurfaces",
    "undoRedoImages",
  ],
  data: () => ({
    priceFormatter: priceFormatter,
    showMenu: false,
    drawer: false,
    partSelected: "",
    surfaceIdHeader: "",
    surfaceIds: surfaceIds,
    isPartBtnActive: true,
    isModeActive: true,
    svgFiles: [
      {
        text: "FRONT",
      },
      {
        text: "BACK",
      },
    ],
    svgMode: {
      text: "FRONT",
    },
    activeMainMenu: "",
    activeSubMenu: "",
    activeSubMenuItem: "",
    defaultWidth: 400,
    chekoutFlag: true,
    searchResultList: [],
    searchInProgress: false,
    lookupCatelog: null,
    showSearchInput: false,
    search: null,
    mobileSearch: null,
    lookupMobileCatelog: null,

    // cart list nav drawer  vars
    stockFlag: true,
  }),
  computed: {
    getImagesForUndoRedo: function () {
      return this.undoRedoImages;
    },
    isMobileDevice() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.partSelected = this.selectedPart;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.surfaceIdHeader = this.partSurface;
      return this.$vuetify.display.xs ? true : false;
    },
    isScrolled: function () {
      if (!this.heroData) return true;
      return this.offsetTop > 30 ? true : false;
    },
    productPage: function () {
      let currentPage = this.$route.name;
      if (
        currentPage === "ProductPersonalizeDesignerMode" ||
        currentPage === "ProductPersonalize" ||
        currentPage === "ProductSummary" ||
        currentPage === "ProductSummaryDesignerMode"
      ) {
        return true;
      }
      return false;
    },
    designerModeFlag: function () {
      if (this.$route.params.designerMode == "true") return true;
      return false;
    },
    productOne: function () {
      return this.$store.state.designerOne.one;
    },
    categoryList: function () {
      return this.$store.state.headerCategoryList.categoryList;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    userName: function () {
      return this.$store.state.accountOne.one.firstname;
    },
    cartDrawer: {
      get() {
        if (this.userAuth) {
          return this.$store.state.userCartProduct.cartDrawer;
        } else {
          return this.$store.state.cartProduct.cartDrawer;
        }
      },
      set() {},
    },
    cartProductsProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductsProgress;
      } else {
        return this.$store.state.cartProduct.cartProductsProgress;
      }
    },
    inProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.inProgress;
      } else {
        return this.$store.state.cartProduct.inProgress;
      }
    },
    cartTotalAmtProgress() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartTotalAmtProgress;
      } else {
        return this.$store.state.cartProduct.cartTotalAmtProgress;
      }
    },
    cartProductsItem() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.cartProductList;
      } else {
        return this.$store.state.cartProduct.cartProductList;
      }
    },
    cartProductTotalAmt() {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.productTotalAmt;
      } else {
        return this.$store.state.cartProduct.productTotalAmt;
      }
    },
    defaultHeight: function () {
      if (this.isDesktop) {
        return window.innerHeight;
      } else {
        return "200";
      }
    },
    isDesktop: function () {
      return this.$vuetify.display.xs ? true : false;
    },
    productListWidth() {
      return window.innerHeight;
    },
    searchInputWidth() {
      return this.$vuetify.display.xs ? "100%" : "256";
    },
  },
  watch: {
    cartProductsItem() {
      this.stockFlagHideShow();
    },
  },
  methods: {
    checkIsPartActive(selectedPart, item) {
      let partId = null;
      if (selectedPart.value) partId = selectedPart.value.id;
      else partId = selectedPart.id;

      if (item.raw.value) {
        return partId == item.raw.value.id ? true : false;
      }
      return false;
    },
    menuAction: function () {
      this.showMenu = !this.showMenu;
      this.$emit("action", { showMenu: !this.showMenu });
    },
    changeCanvas() {
      this.$emit("changeCanvasInMobile", { selectedPart: this.partSelected });
    },
    changePartSurface() {
      this.$emit("changePartSurfaceMobile", {
        selectedPart: this.partSelected,
        partSurface: this.surfaceIdHeader,
      });
    },
    goToStoreLocator() {
      window.open(`${storeFrontUrl}/store-locator`, "_self");
    },
    goToLogout() {
      window.open(`${storeFrontUrl}/logout-success`, "_self");
    },
    goToLogin() {
      window.open(`${storeFrontUrl}/gate/sign-in`, "_self");
    },
    undoOperation() {
      this.$emit("mobileUndoOperation");
    },
    redoOperation() {
      this.$emit("mobileRedoOperation");
    },
    resetOperation() {
      this.$emit("mobileResetOperation");
    },
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },
    goToDashboard() {
      window.open(`${storeFrontUrl}/me/account-dashboard`, "_self");
    },
    stopPropogation(event) {
      event.stopPropagation();
    },
    resizeCart() {
      return this.sideBarHeight() && this.productListHeight();
    },
    productListHeight() {
      return window.innerHeight - 250;
    },
    sideBarHeight() {
      return window.innerHeight;
    },
    outside() {
      // this.cartDrawer=false;
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
    },
    showCartDrawer(flag) {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", flag);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", flag);
      }
    },
    closeCartDrawer(flag) {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", flag);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", flag);
      }
    },
    pageMessage(params) {
      console.log(params);
    },
    showMessage(obj) {},
    goToCheckout() {
      if (this.userAuth) {
        this.$store.dispatch("userCartProduct/cartDrawer", false);
      } else {
        this.$store.dispatch("cartProduct/cartDrawer", false);
      }
      window.open(`${storeFrontUrl}/checkout`, "_self");
    },
    GotoPage(seletedData) {
      this.search = null;
      this.showSearchInput = false;
      this.searchResultList = [];
      let skuNew = seletedData.title;
      window.open(
        `${storeFrontUrl}/search?pageNumber=1&pageSize=9&sortBy=relevance&q=${skuNew}&filter=e30=`,
        "_self"
      );
    },

    //  cart list drawer methods
    stockFlagHideShow() {
      if (this.cartProductsItem) {
        let errorCount = 0;
        let i;
        for (i = 0; i < this.cartProductsItem.length; i++) {
          if (
            (!this.cartProductsItem[i].stockDetails.isInStock ||
              this.cartProductsItem[i].stockDetails.qty <
                this.cartProductsItem[i].qty) &&
            this.cartProductsItem[i].stockDetails.manageStock &&
            this.cartProductsItem[i].stockDetails.backorders == 0
          ) {
            errorCount++;
          }
        }
        if (errorCount) {
          this.stockFlag = false;
        } else {
          this.stockFlag = true;
        }
      }
    },
    showMessageOnPage() {
      this.showMessage();
    },
    showSnackBarMessage(obj) {},
    pageName() {
      return this.$route.name;
    },
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== "function") {
          const compName = vNode.context.name;
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
          if (compName) {
            warn += `Found in component '${compName}'`;
          }

          console.warn(warn);
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        // add Event Listeners
        document.addEventListener("click", handler);
      },
      unbind: function (el, binding) {
        console.warn(binding);
        // Remove Event Listeners
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
};
</script>
<style>
.z-index-100 {
  z-index: 100 !important;
}
</style>
<style src="./ProductPageHeader.scss" lang="scss" scopped />
