// this service will do - to fetch the price of builder session part svg elements that user has changed. Session item dont have summary_data in this case

import { priceProduct, getCurrentSvgPartStructure } from "crane-pricer";

export const getPartStructureFromSvg = async ({ session, sessionItem }) => {
  console.log("Updating products part its assets");
  let skuBasedError = [];
  try {
    let structureProperty = {};
    let partAssets = { papers: [], elements: [] };

    let svgCode = "";

    try {
      svgCode = decodeURIComponent(escape(atob(sessionItem.svgContent)));
    } catch (error) {
      console.log("svg is not encoded");
      svgCode = sessionItem.svgContent;
    }
    let resp = await getCurrentSvgPartStructure(
      svgCode,
      partAssets,
      sessionItem
    );
    structureProperty = resp.partAssets;

    partAssets = JSON.parse(JSON.stringify({ papers: [], elements: [] }));

    console.log("Part assets ", structureProperty);

    return structureProperty;
  } catch (e) {
    // throw e
    console.log("Error in sku ", session.productData?.sku, e);
    let errMsg = e.errorMessage || e;
    if (!skuBasedError.includes(errMsg)) skuBasedError.push(errMsg);
  }
};

export const getPartPricByStructure = async ({
  pricer,
  qty,
  sessionItem,
  partStructure,
  options,
  orderPriceObject,
}) => {
  try {
    let skuBasedError = [];
    let pricerResp = null;

    if (orderPriceObject) {
      pricerResp = await priceProduct({ pricer, orderPriceObject, options, currentSessionId: sessionItem.currentSessionId });
    } else {
      let quantity = qty || 50;
      let productConfiguration = {
        configuredStructure: partStructure,
        qty: quantity,
      };
      pricerResp = await priceProduct({
        pricer,
        productConfiguration,
        options,
        currentSessionId: sessionItem.currentSessionId
      });
    }

    if (pricerResp.status === "success") {
      return {
        oldPrice: pricerResp.omsPrice,
        newPrice: pricerResp.cloudPrice,
      };
    } else {
      if (pricerResp.error.includes("properties of undefined")) {
        console.log(pricerResp.error);

        let errMsg = `The current part "${
          sessionItem.partName || sessionItem.name || ""
        }" have broken svg, please contact to support team`;
        if (!skuBasedError.includes(errMsg)) skuBasedError.push(errMsg);
        return {
          skuBasedError,
          oldPrice: 0,
          newPrice: 0,
        };
      } else if (!skuBasedError.includes(pricerResp.error)) {
        skuBasedError.push(pricerResp.error);
        return {
          skuBasedError,
          oldPrice: 0,
          newPrice: 0,
        };
      }
    }
    return {
      skuBasedError,
      oldPrice: 0,
      newPrice: 0,
    };
  } catch (e) {
    // throw e
    console.log("Error in sku ", e);
    throw e;
  }
};
